import { useState, useEffect } from "react";
import { db } from "../../firebase-config";
import { collection, onSnapshot } from "firebase/firestore";
import CircleLoader from "react-spinners/CircleLoader";
import Helmet from "react-helmet";

export const Favourites = () => {
  const [favourites, setFavourites] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Listen REAL-TIME
    const getFavourites = onSnapshot(
      collection(db, "favourites"),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setFavourites(list);
        setLoading(false); // Set loading to false when data is fetched
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      getFavourites();
    };
  }, []);

  return (
    <div className="mx-2 mb-6 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 md:my-8 md:mx-4 lg:mx-6 lg:grid-cols-4 xl:mx-12">
      {loading ? (
        <div className="mt-12 flex w-screen items-center justify-center">
          <CircleLoader
            loading={loading}
            size={40}
            color="#fcd116"
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        favourites.map((favourites) => (
          <>
            <Helmet>
              <title>Favourites</title>
              <meta
                name="description"
                content="Discover the authentic taste of Filipino cuisine in Hamilton. Enjoy crispy pork dishes, adobo, sisig and other traditional favorites that bring the rich flavors of the Philippines to your table."
              />
              <meta
                name="keywords"
                content="bagnet hamilton, crispy pork hamilton, bopis hamilton, sisig hamilton, kapampangan sisig hamilton, embutido hamilton, chicken adobo hamilton, crispy pata hamiltob, fried pork belly hamilton, bangus hamilton, fried chicken hamilton, filipino favourites"
              />
            </Helmet>
            <div
              key={favourites.id}
              className="relative h-fit rounded-lg bg-yellowed/10 font-primary tracking-wide shadow-lg 2xl:mt-4"
            >
              <div className="xs:aspect-h-4 aspect-w-5 aspect-h-5 md:aspect-h-4 lg:aspect-w-6 lg:aspect-h-4 2xl:aspect-w-7">
                <img
                  src={favourites.image}
                  alt=""
                  className="rounded-t-lg object-cover shadow-lg"
                />
              </div>

              <div className="h-[10.5rem] p-4 md:h-[8rem] 2xl:h-[12rem] 2xl:p-6">
                <div className="space-y-2 2xl:space-y-4">
                  <h5 className="text-sm font-medium text-yellowed 2xl:text-lg">
                    {favourites.name}
                  </h5>
                  <p className="text-xs font-thin leading-relaxed text-white 2xl:text-lg">
                    {favourites.description}
                  </p>
                </div>
              </div>
            </div>
          </>
        ))
      )}
    </div>
  );
};
