import { ContactD } from "../components/ContactD";
import { TypingEffect } from "../components/TypingEffect";
import { Footer } from "../components/Footer";
import PacmanLoader from "react-spinners/PacmanLoader";
import { useEffect, useState, lazy, Suspense } from "react";
import "pattern.css/dist/pattern.min.css";
import CircleLoader from "react-spinners/CircleLoader";

const IntroDine = lazy(() => import("../components/IntroDine"));
const IntroCater = lazy(() => import("../components/IntroCater"));
const IntroFtruck = lazy(() => import("../components/IntroFtruck"));

export const Intro = () => {
  // Online links
  const urls = [
    {
      name: "Doordash",
      url: "https://www.doordash.com/store/mabuhay-bbq-and-grill-hamilton-30856988/43852157/?preview=1",
    },
    { name: "facebook", url: "https://www.facebook.com/MabuhayBBQandGrill" },
  ];

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return isLoading ? (
    <div className="bg-intro flex h-screen items-center justify-center bg-gradient-primary ">
      <PacmanLoader
        loading={isLoading}
        size={40}
        color="#fcd116"
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  ) : (
    <div className="bg-intro w-screen space-y-24 bg-gradient-primary 2xl:space-y-52">
      {/* Hero */}

      <div className="z-50 h-screen w-screen bg-heroImage bg-cover">
        <div className="backdrop-blur-xs h-full w-full bg-black/60">
          <div className="flex h-full w-full flex-col items-center justify-center space-y-4 text-center font-body text-white 2xl:space-y-6">
            <TypingEffect />
            <p className="font-hand text-xl tracking-widest text-yellowed 2xl:text-3xl">
              Cooking since 2012
            </p>
          </div>
        </div>
        <div className="pattern-dots-md h-10 text-yellowed"></div>
      </div>

      {/* //Resto info */}

      <div>
        <ContactD urls={urls} />
      </div>

      <Suspense
        fallback={
          <div className="flex items-center justify-center">
            <CircleLoader
              size={40}
              color="#fcd116"
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        }
      >
        {/* //slides */}

        <div className="flex w-full flex-col gap-28">
          <div>
            <IntroDine urls={urls} />
          </div>

          <div>
            <IntroCater />
          </div>

          <div>
            <IntroFtruck />
          </div>
        </div>
      </Suspense>

      <Footer />
    </div>
  );
};
