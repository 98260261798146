import Helmet from "react-helmet";
import { SiDoordash } from "react-icons/si";
import { BsFacebook } from "react-icons/bs";

export const ContactD = ({ urls }) => {
  //access online links
  const handleClick = (name) => {
    const url = urls.find((item) => item.name === name).url;
    window.open(url);
  };

  const restaurant = (
    <div className="flex w-full flex-col items-center gap-4 tracking-wide">
      <h1 className="font-hand text-xl text-yellowed 2xl:text-3xl">
        Restaurant
      </h1>
      <p className="flex flex-col gap-2 text-center text-sm font-thin 2xl:text-lg">
        <span>7 Collingwood St.</span>
        <span>Hamilton, New Zealand</span>
      </p>
    </div>
  );

  const foodtruck = (
    <div className="flex w-full flex-col items-center gap-4 tracking-wide">
      <h1 className="font-hand text-xl text-yellowed 2xl:text-3xl">
        Food truck
      </h1>
      <p className="flex flex-col gap-2 text-center text-sm font-thin 2xl:text-lg">
        Hamilton Night Market
      </p>
    </div>
  );

  const hours = (
    <div className="flex w-full flex-col items-center gap-4 2xl:gap-6">
      <h1 className="font-hand text-xl text-yellowed 2xl:text-3xl">Hours</h1>
      <h2 className="text-sm font-thin text-red-200 2xl:text-lg">
        Tuesday - Thursday & Saturday
      </h2>
      <p className="text-sm font-thin 2xl:text-lg">
        <span className="text-xs 2xl:text-sm">Lunch:</span> 11:30am - 2:30pm
      </p>
      <p className="text-sm font-thin 2xl:text-lg">
        <span className="text-xs font-thin 2xl:text-sm">Dinner:</span> 4:30pm -
        7:30pm
      </p>

      <h2 className="text-sm font-thin text-red-200 2xl:text-lg">Friday</h2>
      <p className="text-sm font-thin 2xl:text-lg">
        <span className="text-xs 2xl:text-sm">Lunch:</span> 11:30am - 2pm
      </p>
      <p className="text-sm font-thin 2xl:text-lg">
        <span className="text-xs font-thin 2xl:text-sm">Night Market:</span> 5pm
        - 8pm
      </p>

      <h2 className="text-sm font-thin text-red-200 2xl:text-lg">Sunday</h2>
      <p className="text-sm font-thin 2xl:text-lg">
        <span className="text-xs 2xl:text-sm">Lunch:</span> 11:30am - 2pm
      </p>
      <p className="text-sm font-thin 2xl:text-lg">
        <span className="text-xs font-thin 2xl:text-sm">Night Market:</span> 5pm
        - 8pm
      </p>

      <h2 className="text-sm font-thin text-red-200 2xl:text-lg">Monday</h2>
      <p className="text-sm font-thin 2xl:text-lg">CLOSED</p>
    </div>
  );

  const online = (
    <div className="flex w-fit flex-col items-center gap-2 text-sm 2xl:gap-5 2xl:text-xl">
      <h1 className="mb-2 font-hand text-xl text-yellowed 2xl:text-3xl">
        Delivery
      </h1>
      <p
        className="cursor-pointer text-5xl 2xl:text-7xl"
        onClick={() => handleClick("Doordash")}
      >
        <SiDoordash />
      </p>
    </div>
  );

  const contact = (
    <div className="flex w-full flex-col items-center gap-2 text-sm 2xl:gap-6 2xl:text-xl">
      <h1 className="mb-2 font-hand text-xl text-yellowed 2xl:text-3xl">
        Contact
      </h1>
      <p className="flex font-thin">07-981-1221</p>
      <p
        className="mt-4 cursor-pointer text-2xl 2xl:text-4xl"
        onClick={() => handleClick("facebook")}
      >
        <BsFacebook />
      </p>
    </div>
  );

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="description"
          content="Mabuhay bbq and grill business information"
        />
        <meta
          name="keywords"
          content="mabuhay bbq and grill address, mabuhay bbq and grill business hours, mabuhay bbq and grill ubereats"
        />
      </Helmet>
      <div className="mt-28 flex w-screen cursor-default items-center justify-center text-white">
        <div className="grid w-full justify-center gap-10 font-primary md:flex md:grid-cols-4 md:gap-20 lg:w-2/3 lg:justify-around lg:gap-8">
          {/* //START */}

          <div className="flex h-full flex-col gap-10 2xl:gap-16">
            <div className="flex h-full flex-col items-center">
              {restaurant}
            </div>

            <div className="flex h-full flex-col items-center">{foodtruck}</div>
          </div>

          <div className="flex h-full flex-col items-center">{hours}</div>

          <div className="flex h-full flex-col gap-10 2xl:gap-16">
            <div className="flex h-full flex-col items-center">{online}</div>

            <div className="flex h-full flex-col items-center">{contact}</div>
          </div>
        </div>
      </div>
    </>
  );
};
